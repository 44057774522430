$yellow: #fddd00;
$light-yellow: #fdeb93;
$teal: #26a69a;
$color-background: #222222;
$color-text: #ffffff;
$font-size: 16px;

html {
  cursor: url('../images/yellow-star.cur'), auto;
  scrollbar-color: $yellow $color-background;
  scrollbar-track-color: $color-background;
  scrollbar-arrow-color: $color-background;

  ::-webkit-scrollbar-thumb {
    background-color: $yellow;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: $color-background;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  position: relative;
  height: 100%;
}
body {
  font-family: ‘SUIT Variable’, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  word-break: keep-all;
  /* background: var(--color-background); */
  // background-color: rgba(0, 0, 0, 0.8);
  background-color: $color-background;
  box-sizing: border-box;

  overflow: hidden;
  overflow-y: scroll;
  //   font-size: 16x;
  font-size: calc(1rem + 0.25vw);

  line-height: 1.6;
  position: relative;
  height: 100%;
}

canvas {
  // position: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100dvh;
}

main {
  width: 100%;
  height: 100%;
  max-width: 800px;
  margin: 0 auto;
  // margin-top: 16rem;
  // max-width: 800px;
  @media screen and (max-width: 768px) {
    // margin-top: 8rem;
    width: 98%;
  }
}

body {
  color: $color-text;
  // padding: 0% 4%;
}
.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  max-width: 800px;
  margin: 0 auto;
}

.info,
.intro,
.slide {
  /* padding: 0 1em; */
  margin: 0 auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

section {
  width: 100%;
  // height: 100%;
}
hr {
  border: 0;
  border-bottom: 1px solid $yellow;
  margin: 2em 0 2rem;
  max-width: 800px;
}
.bold {
  font-weight: bold;
}

b {
  color: $yellow;
  font-weight: 700;
}

.subtitle {
  // position: relative;
  // top: 0;
  // left: -6rem;
  width: 100%;
  font-size: 2.5rem;
  font-weight: 900;
  @media screen and (max-width: 768px) {
    // left: 0;
    font-size: 1.25rem;
    padding-bottom: 0;
  }
}

footer {
  position: fixed;
  // bottom: 2rem;
  bottom: 1rem;
  right: 2rem;
  max-width: 800px;
  font-size: 0.75rem;
  font-weight: 700;
  font-style: italic;
  color: $yellow;
  @media screen and (max-width: 768px) {
    right: 0.5rem;
    bottom: 1rem;
    font-size: 0.5rem;
  }
}
.main-title {
  height: 100vh;
}

#quote-section {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translateX(-50%);
  .main-quote {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $yellow;
    text-shadow: 0 0 0.5em $yellow;
    div {
      text-align: center;
    }
    @media screen and (max-width: 768px) {
      padding: 1rem;
      align-items: flex-start;
      div {
        text-align: left;
      }
    }
  }
}

.space {
  width: 100%;
  height: calc(4rem);
}

.main-quote {
  h1 {
    margin: 0;
    padding: 0;
    font-size: 2.5rem;
    font-weight: 900;
  }
  blockquote {
    padding-bottom: 1rem;
    h2 {
      margin: 0;
      padding: 0;
    }
  }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 1.5rem;
    }
  }
}
.text-change {
  text-align: left;
  font-size: 1rem;
  height: 1rem * 1.5;
  line-height: 1rem * 1.5;
  overflow: hidden;
  font-weight: bold;
  // margin-top: -$font-size * 0;
  // top: -2rem;
  .element {
    margin: 0;
    animation: rotate-text 8s ease-in-out infinite;
    // color: $yellow;
  }
}
@keyframes rotate-text {
  0%,
  20% {
    transform: translateY(0);
  }
  25%,
  45% {
    transform: translateY(1rem * -1.5);
  }
  50%,
  70% {
    transform: translateY(1rem * -3);
  }
  75%,
  95% {
    transform: translateY(1rem * -4.5);
  }
  100% {
    transform: translateY(1rem * -6);
  }
}
.info {
  // height: 100vh;
  height: 100%;

  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  h1 {
    margin-top: 2rem;
    margin-bottom: 0rem;
    padding-bottom: 0;
    font-weight: 900;
    font-size: 4rem;
  }
  h4 {
    margin: 0;
    padding: 0.25rem 0;
    font-weight: 900;
    font-size: 1.5em;
  }
  li {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.4;
  }
  a {
    color: $yellow;
    text-decoration: none;
  }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 2rem;
    }
    h4 {
      font-size: 1rem;
    }
    li {
      font-size: 1rem;
    }
  }
}
.crescent {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 2rem;
  @media (max-width: 1024px) {
    padding-right: 4rem;
  }
  @media screen and (max-width: 768px) {
    padding-right: 2rem;
  }
}

@keyframes crescent {
  0% {
    transform: translate(-30px, 30px) scale(0.9);
    box-shadow: none;
  }
  50% {
    transform: translate(0px, 0px) scale(1.02);
    box-shadow: 0 0 0 10px #f9f9f9, 0, 0, 80px $yellow;
    background-color: $light-yellow;
  }
  100% {
    transform: translate(30px, -30px) scale(0.9);
  }
}

.moon {
  background-image: linear-gradient(30deg, $color-background, $color-background);
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  position: relative;
  @media screen and (max-width: 768px) {
    width: 4rem;
    height: 4rem;
  }
}
.moon::before {
  content: '';
  background-color: $yellow;
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  z-index: -1;
  animation: 3.2s crescent linear infinite alternate;
}

h1.yellow,
h2.yellow,
h3.yellow,
h3.white {
  margin: 0;
}
h1.yellow {
  font-size: 1.75rem;
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
}
.yellow {
  color: $yellow;
  font-weight: bolder;
}
.white {
  color: $color-text;
  font-weight: bolder;
}
section {
  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }

  h3 {
    font-weight: bolder;
  }
  a {
    color: $yellow;
    text-decoration: none;
  }
  .project-title {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    align-items: flex-end;
  }
  .project-title > h4::before {
    content: ', ';
  }

  @media screen and (max-width: 768px) {
    .project-title {
      flex-direction: column;
      align-items: flex-start;
    }
    .project-title > h4::before {
      content: '';
    }
  }
}

article,
hgroup {
  @media screen and (max-width: 768px) {
    padding: 0 1rem;
  }
}
// .intro {
//   @media screen and (max-width: 768px) {
//     height: 80vh;
//   }
// }

.intro {
  max-width: 800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  padding: 0 2rem;

  @media screen and (max-width: 768px) {
    padding: 0 1rem;
    height: 100%;
  }
}
.slide-top {
  justify-content: end;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}
.slide-bottom {
  justify-content: start;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}

.shine {
  text-shadow: 0 0 0.5em $yellow;
}

.info,
hgroup,
.project {
  padding: 0 2rem;
  @media screen and (max-width: 768px) {
    padding: 0 1rem;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  max-width: 800px;
  margin: 0 auto;
  a {
    margin: 0;
    color: $yellow;
    text-decoration: none;
    h4 {
      font-weight: 800;
    }
  }
  .nav-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .nav-link a {
    margin-left: 0.75em;
    color: $yellow;
  }
  @media screen and (max-width: 768px) {
    padding: 0 0.5rem;
    margin-left: 0.5rem;
    .nav-link a {
      margin-left: 0.5em;
    }
  }
}
