$yellow: #fde047;
$light-yellow: #fdeb93;
$color-background: #222222;
$color-text: #ffffff;
$font-size: 16px;

/* github markdonw style*/
body h1 {
  margin: 0.67em 0;
  font-weight: 600;
  padding-bottom: 0.3em;
  font-size: 2em;
  /* border-bottom: 1px solid #21262d; */
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  /* line-height: 1.25; */
}

body h2 {
  font-weight: 600;
  padding-bottom: 0.3em;
  font-size: 1.5em;
  /* border-bottom: 1px solid #21262d; */
}

body h3 {
  font-weight: 600;
  font-size: 1.25em;
}

body h4 {
  font-weight: 600;
  font-size: 1em;
}

body h5 {
  font-weight: 600;
  font-size: 0.875em;
}

body h6 {
  font-weight: 600;
  font-size: 0.85em;
  /* color: #8b949e; */
}

body p {
  margin-top: 0;
  margin-bottom: 10px;
}

body blockquote {
  margin: 0;
  padding: 0 1em;
  /* color: #8b949e; */
  /* border-left: 0.25em solid #30363d; */
  border-left: 0.25em solid $yellow;
  background-color: rgba(225, 225, 225, 0.1);
}

body ul,
body ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2em;
}

body ul li::before {
  content: '- ';
  color: $yellow;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

body ol ol,
body ul ol {
  list-style-type: lower-roman;
}

body ul ul ol,
body ul ol ol,
body ol ul ol,
body ol ol ol {
  list-style-type: lower-alpha;
}

body dd {
  margin-left: 0;
}
